import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserHelper from "../../core/UserHelper";
import { LoginPage, PaymentPage, PaymentsPage } from "../../core/PageConstants";
import { postAsync } from "../../core/serviceClient";
import Utils from "../../core/Utils";
import "../../Style.css";
import { useDispatch } from "react-redux";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CancelIcon from '@mui/icons-material/Cancel';
import CloudSyncIcon from '@mui/icons-material/Refresh';
import ComplimentaryPopup from "../common/Complimentary"
import {
  Container,
  Stack,
  Grid,
  Typography,
  Button,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import Loader from "../common/Loader";
import MessagePopup from "../common/MessagePopUp";
import SideBarMenuItems from "../SideBarMenuItems";
import {
  getBranch,
  getBusiness,
  getCountry,
} from "../../data/localSettingsActions";
import {
  ConfirmTypes,
  OfferTypes,
  PaymentMethodTypes,
  RazorpayPaymentLinkStatuses,
  VisitStatuses,
  VisitTypeEnum,
} from "../../core/Constants";
import AmountTypography from "../common/controls/AmountTypography";
import { convertServiceItemObject } from "../../helpers/productHelper";
import { ConfirmDialog } from "../common/ConfirmDialog";

const GetPaymentsForVisitErrorTypes =
{
  None: "None",
  VisitNotFound: "VisitNotFound",
  PaymentNotFound: "PaymentNotFound",
  CustomerNotFound: "CustomerNotFound"
}

const Payments = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  //useState hooks
  const [total, setTotal] = useState(0);
  const [paid, setPaid] = useState(0);
  const [requested, setRequested] = useState(0);
  const [outstanding, setOutstanding] = useState(0);
  const [refunded, setRefunded] = useState(0);
  const [visit, setVisit] = useState({});
  const [pass, setPass] = useState({});
  const [payment, setPayment] = useState({});
  const [paymentInfos, setPaymentInfos] = useState([]);
  const [canPay, setCanPay] = useState(false);
  const [isNewPayment, setIsNewPayment] = useState(false);
  const [business, setBusiness] = useState({});
  const [branch, setBranch] = useState({});
  const [discountAmount, setDiscountAmount] = useState(0);
  const [settlementAccounts, setSettlementAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [country, setCountry] = useState({});
  const [showComp, setShowComp] = useState(false);
  const [partialPayment, setPartialPayment] = useState(false);

  //Loader hook
  const [isLoading, setIsLoading] = useState(false);

  //Alert Hooks
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [backNavigationTriggered, setBackNavigationTriggered] = useState(false);

  //Confirm hooks
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  //Compliment hooks
  const [openComplimentaryModal, setOpenComplimentaryModal] = useState(false);
  const [compType, setCompType] = useState("Discount");
  const [compValue, setCompValue] = useState(0);
  const [orderComplimentaryList, setOrderComplimentaryList] = useState([]);
  const [currentDiscount, setCurrentDiscount] = useState({});
  const [comps, setComps] = useState([]);
  const [tempCompObject, setTempCompObject] = useState({});
  const [canAddCompliments, setCanAddCompliments] = useState(false);

  const [maxPrice, setMaxPrice] = useState(0);

  const setConfirm = (msg) => {
    setConfirmMessage(msg);
    setShowConfirm(true);
  }

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = async () => {
    if (UserHelper.CheckPermission(PaymentsPage, navigate)) {
      setIsLoading(true);
      var _business = getBusiness();
      var _branch = getBranch();
      setBusiness(_business);
      setBranch(_branch);
      if (!Utils.IsNullOrEmptyArray(_branch.payout)) {
        var isDefaultAvailable = _branch.payout.some(
          (p) => p.isDefault === true
        );
        setSettlementAccounts(_branch.payout);
        setSelectedAccount(
          isDefaultAvailable
            ? _branch.payout.filter((p) => p.isDefault === true)[0]
            : _branch.payout[0]
        );
      }
      setCountry(getCountry());
      setIsLoading(true);
      var currentVisit = location.state.visit;
      var result = await postAsync("PaymentIN/GetPaymentsForVisit", {
        VisitId: currentVisit.id,
        BusinessId: currentVisit.businessId,
      });
      setIsLoading(false);
      if (result.error) {
        setIsNavigateToLogin(result.isNavigateToLogin);
        setAlert(result.errorMessage);
        return;
      }
      switch (result.data.errorType) {
        case GetPaymentsForVisitErrorTypes.None:
          setVisit(
            Utils.IsNullOrEmptyObject(result.data.visit)
              ? {}
              : result.data.visit
          );
          setPayment(
            Utils.IsNullOrEmptyObject(result.data.payment)
              ? {}
              : result.data.payment
          );
          setPass(
            Utils.IsNullOrEmptyObject(result.data.customerPass)
              ? {}
              : result.data.customerPass
          );
          break;
        default:
          setAlert(result.data.errorMessage);
          setBackNavigationTriggered(true);
          break;
      }
    }
  };

  useEffect(() => {
    populatePayments();
  }, [payment]);

  const populatePayments = () => {
    if (
      Utils.IsNullOrEmptyObject(visit) ||
      Utils.IsNullOrEmptyObject(payment)
    ) {
      setRefunded(0);
      setOutstanding(0);
      setRequested(0);
      setPaid(0);
      setTotal(0);
      setIsNewPayment(true);
      setDiscountAmount(0);
      setPaymentInfos([]);
      return;
    }
    var _total = visit.spend;
    var _outstanding = 0;
    var _requested = 0;
    var _paid = 0;
    var _refunded = 0;
    var _discount = visit.discount;
    payment.payments.forEach((paymentInfo) => {
      if (paymentInfo.captured === true) {
        _paid += paymentInfo.capturedAmount;
      } else if (paymentInfo.refunded === true) {
        _refunded += paymentInfo.refundAmount;
      } else if (
        !Utils.IsNullOrEmpty(paymentInfo.paymentLinkId) &&
        paymentInfo.status != RazorpayPaymentLinkStatuses.Cancelled
      ) {
        _requested += paymentInfo.orderAmount;
      }
    });
    var _tax = visit.isTaxIncluded ? visit.serviceItems.map((c) => c.taxAmount).reduce((a, b) => a + b).toFixed(2) : 0;
    var tempTotal = Utils.ConvertToFloat(_total) + Utils.ConvertToFloat(_tax);
    _outstanding = tempTotal - (_paid - _refunded) - _discount;
    setTotal(Utils.ConvertToFloat(tempTotal.toFixed(2)));
    setOutstanding(_outstanding);
    setPaid(_paid);
    setRefunded(_refunded);
    setDiscountAmount(_discount);
    setRequested(_requested);
    setIsNewPayment(false);
    var _partialPay = (visit.paidAmount > 0 && visit.paidAmount < visit.paymentAmount) || (visit.requestedAmount > 0 && visit.requestedAmount < visit.paymentAmount);
    setPartialPayment(_partialPay);
    setPaymentInfos(payment.payments.sort((a, b) => a.createdDT - b.createdDT).reverse());
    var _canPay =
      (visit.status !== VisitStatuses.Delivered &&
        visit.status !== VisitStatuses.Void &&
        visit.status !== VisitStatuses.PaymentRefunded &&
        visit.status != VisitStatuses.ReservationCanceled &&
        (_outstanding - _requested) > 0) || (visit.status === VisitStatuses.Delivered && visit.paidAmount < (visit.paymentAmount - _requested));
    setCanPay(_canPay);
    setCanAddCompliments(_canPay);
  };
  useEffect(() => {
    handlePriceMaxLimit();
  }, [compType]);
  const handlePriceMaxLimit = () => {
    if (!Utils.IsNullOrEmptyArray(visit.serviceItems)) {
      var _totalPrice = visit.serviceItems.reduce((sum, item) => sum + item.totalPrice, 0);
      var _totalDiscount = visit.serviceItems.reduce((sum, item) => sum + item.discountTotal, 0);
      var test = compType === "Discount" ? 100 : _totalPrice - _totalDiscount
      setMaxPrice(test);
    }
  }

  const clickOpenComplimentary = () => {
    handlePriceMaxLimit();
    setOpenComplimentaryModal(true);
  };


  const handleAlertClose = () => {
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
      return;
    }
    if (backNavigationTriggered) {
      backNavigation();
      setBackNavigationTriggered(false);
    }
  };

  const createNewPaymentLink = async () => {
    setIsLoading(true);
    var paymentsCount = "1";
    if (!Utils.IsNullOrEmptyArray(paymentInfos)) {
      var serialNumberArray = paymentInfos.map((p) => p.serialNumber);
      var max = Math.max(...serialNumberArray);
      paymentsCount = (max + 1).toString();
    }
    var refId = visit.id + "-" + visit.id + "-" + paymentsCount;
    var request = {
      Amount: outstanding,
      BusinessId: business.id,
      BranchId: branch.branchId,
      CustomerName: visit.customerName,
      JobCardNo: visit.jobcardNumber,
      MobileNumber: Utils.IsNullOrEmpty(visit.customerMobileNumber)
        ? visit.receiptMobileNumber
        : visit.customerMobileNumber,
      Email: visit.customerEmailId,
      CustomerId: visit.customerId,
      VisitId: visit.id,
      ReferenceId: refId,
      SettlementAccountId: Utils.IsNullOrEmptyObject(selectedAccount)
        ? ""
        : selectedAccount.payoutAccountId,
      SettlementAccountAlias: Utils.IsNullOrEmptyObject(selectedAccount)
        ? ""
        : selectedAccount.accountAlias,
    };
    var result = await postAsync("PaymentIN/CreatePaymentLink", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    setPayment(result.data.payment);
  };

  const updatePayment = async (paymentInfo, e) => {
    e.stopPropagation();
    var request = {
      PaymentLinkId: paymentInfo.paymentLinkId,
      PaymentId: Utils.IsNullOrEmptyObject(payment) ? "" : payment.id,
      BusinessId: business.id,
      VisitId: visit.id,
    };
    setIsLoading(true);
    var result = await postAsync("PaymentIN/UpdatePaymentLink", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    setVisit(result.data.visit);
    setPayment(result.data.payment);
  };

  const cancelPayment = async (paymentInfo, e) => {
    e.stopPropagation();
    var request = {
      PaymentLinkId: paymentInfo.paymentLinkId,
      PaymentId: Utils.IsNullOrEmptyObject(payment) ? "" : payment.id,
      BusinessId: business.id,
      VisitId: visit.id,
      PaymentInfoId: paymentInfo.paymentId,
    };
    setIsLoading(true);
    var result = await postAsync("PaymentIN/CancelPaymentLink", request);
    setIsLoading(false);
    if (result.error) {
      setIsNavigateToLogin(result.isNavigateToLogin);
      setAlert(result.errorMessage);
      return;
    }
    setVisit(result.data.visit);
    setPayment(result.data.payment);
  };

  const openLink = (url) => {
    window.open(encodeURI(url), "_blank");
  };

  const UpdateVisitOnCompApplied = async () => {
    var _visit = visit;
    if (!Utils.IsNullOrEmptyArray(comps)) {
      setIsLoading(true);
      var request = {
        VisitId: _visit.id,
        Comps: comps,
        IsTaxIncluded: _visit.IsTaxIncluded
      }
      var result = await postAsync("Customer/UpdateVisitOnCompApplied", request);
      setIsLoading(false);
      if (result.error) {
        setIsNavigateToLogin(result.isNavigateToLogin);
        setAlert(result.errorMessage);
        return;
      }
      return _visit = result.data.visit;
    }
    return _visit
  }
  const proceedPayment = async () => {
    var _visit = await UpdateVisitOnCompApplied();
    var _pass = pass;
    var newState = {
      ...location.state,
      onSuccess: PaymentsPage.Path,
      navigationStack: Utils.AddElementToArray(
        location.state.navigationStack,
        PaymentsPage.Path
      ),
      visit: _visit,
      pass: _pass,
    };
    if (!partialPayment) {
      var serviceItems = [];
      _visit.serviceItems.forEach((item) => {
        var sItem = convertServiceItemObject(item, true);
        serviceItems.push(sItem);
      })
    }
    if (partialPayment === true) {
      newState = {
        ...newState,
        isEdit: false,
        partialPayment: partialPayment,
        serviceItems: serviceItems,
        vehicleDetails: _visit.vehicleDetails
      }
    } else {
      newState = {
        ...newState,
        isEdit: true,
        partialPayment: true,
        serviceItems: serviceItems,
        vehicleDetails: _visit.vehicleDetails
      }
    }
    navigate(PaymentPage.Path, { state: newState });
  };

  const backNavigation = async () => {
    await UpdateVisitOnCompApplied(visit);
    var tax = visit.serviceItems.reduce((sum, item) => sum + item.taxAmount, 0);
    var _total = visit.spend + tax;
    var outstanding = _total - visit.paidAmount - visit.discount;
    if (visit.status === VisitStatuses.PartiallyPaid && outstanding === 0) {
      await UpdateVisitStatus();
    }
    navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
      state: {
        ...location.state,
        navigationStack: Utils.RemoveLastElementFromArray(
          location.state.navigationStack
        ),
      },
    });
  }

  const clickCloseComplimentary = () => {
    setOpenComplimentaryModal(false);
  };

  const addComp = () => {
    var compDesc = "Order Complimentary";
    var discountType = compType === "Discount" ? OfferTypes.PercentDiscount : OfferTypes.Cash;
    var compDescDetailed =
      compType === "Discount"
        ? "Discount Percentage " + compValue + "%"
        : "Cash Back " + Utils.GetCurrency(compValue, country);
    var compId = Utils.CreateGuid();
    var discountTotal = !Utils.IsNullOrEmptyArray(orderComplimentaryList) ? orderComplimentaryList.map((o) => o.discount).reduce((a, b) => a + b) : 0;
    var currentDiscountTotal = 0;
    var newCompValue = parseFloat(compValue);
    if (newCompValue > 0) {
      var serviceItemTotalPrice = visit.serviceItems.map((s) => s.totalPrice).reduce((a, b) => a + b);
      var serviceItemDiscountTotal = visit.serviceItems.map((s) => s.discountTotal).reduce((a, b) => a + b);
      currentDiscountTotal = discountType === OfferTypes.PercentDiscount
        ? (serviceItemTotalPrice - serviceItemDiscountTotal) * newCompValue / 100
        : newCompValue;
      currentDiscountTotal = Utils.ConvertToFloat(currentDiscountTotal.toFixed(2));
      discountTotal += currentDiscountTotal;
      if (discountTotal > outstanding) {
        setAlert("The discount amount cannot exceed the outstanding balance.");
        return;
      }
      var discount = {
        CampaignId: compId,
        BenefitId: "",
        CampaignType: "Advertisement",
        OffersClassificationType: "Campaign",
        BenefitType: "DiscountPercentage",
        Code: compDescDetailed,
        Description: compDesc,
        DescriptionDetail: compDescDetailed,
        Quantity: 0.0,
        Complimentary: true,
        OrderLevel: true,
        PointsRedemption: false,
        DiscountType: discountType,
        DiscountValue: newCompValue,
        DiscountTotal: currentDiscountTotal,
      };
      setCurrentDiscount(discount);
      var newComp = {
        orderLevel: true,
        productId: "",
        value: newCompValue,
        type: discountType,
        discount: currentDiscountTotal,
        compId: compId,
      }
      setTempCompObject(newComp);
      setConfirm("Are you sure want to add discount of " + currentDiscountTotal + " to this visit?");
    }
  }

  const removeComp = (index) => {
    var orderComp = orderComplimentaryList[index];
    if (!Utils.IsNullOrEmptyObject(orderComp)) {
      setComps(comps.filter((c) => c.CampaignId !== orderComp.compId));
      setOrderComplimentaryList(orderComplimentaryList.filter((o) => o.compId !== orderComp.compId));
      setDiscountAmount(discountAmount - orderComp.discount);
      var _outstanding = outstanding + orderComp.discount;
      setCanAddCompliments(_outstanding > 0);
      setOutstanding(_outstanding);
    }
  }

  const addDiscount = () => {
    var tempComps = [...comps];
    tempComps.push(currentDiscount);
    setComps(tempComps);
    var tempCompList = [...orderComplimentaryList];
    tempCompList.push(tempCompObject);
    var _outstanding = outstanding - currentDiscount.DiscountTotal;
    setOrderComplimentaryList(tempCompList);
    setDiscountAmount(discountAmount + currentDiscount.DiscountTotal);
    setOutstanding(_outstanding);
    setCanAddCompliments(_outstanding > 0);
    setCurrentDiscount({});
    setTempCompObject({});
  }

  const closeConfirm = (confirmResult) => {
    setConfirmMessage("");
    setShowConfirm(false);
    switch (confirmResult) {
      case ConfirmTypes.YES:
      case ConfirmTypes.OK:
        addDiscount();
        break;
      default:
        setCurrentDiscount({});
        setTempCompObject({});
        return;
    }
  }
  async function UpdateVisitStatus() {
    setIsLoading(true);
    let response = await postAsync("Payment/UpdateVisitStatus", {
      BusinessId: business.id,
      VisitId: visit.id,
      VisitStatus: visit.status,
      SendNotification: false,
    });
    setIsNavigateToLogin(response.isNavigateToLogin);
    if (response.error) {
      setAlert(response.errorMessage);
    }
    setIsLoading(false);
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      {/* Loader */}
      <Loader open={isLoading} />

      {/* Confirm Popup */}
      <ConfirmDialog open={showConfirm}
        message={confirmMessage}
        onClose={closeConfirm} />

      {/* Message Popup */}
      <MessagePopup
        msgOpen={showAlert}
        msgText={alertMessage}
        onMsgClose={handleAlertClose}
      />

      {/* Add Comp */}
      <ComplimentaryPopup
        open={openComplimentaryModal}
        compType={compType}
        compTypeSetter={setCompType}
        orderComplimentaryList={orderComplimentaryList}
        closeComplimentary={clickCloseComplimentary}
        countryInfo={country}
        addComp={addComp}
        removeCompItem={removeComp}
        compValueSetter={setCompValue}
        compValue={compValue}
        maxLimit={maxPrice}
        maxLimitSetter={setMaxPrice}
      />

      {/* Main */}
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={location.state.activeMenu} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <Grid flex="1" spacing={2} padding="20px">
                {/*Title*/}
                <Grid
                  container
                  className="title-sec"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    <Button
                      onClick={() => backNavigation()}
                      className="back-btn"
                    >
                      <NavigateBeforeIcon />
                    </Button>
                    Payments
                  </Typography>
                  <Stack direction="row"
                    spacing={2}>
                    {canAddCompliments && (<Paper>
                      <Button
                        className="btn btn-primary btn-sm white-text align-self-start"
                        variant="contained"
                        onClick={() => clickOpenComplimentary()}>
                        Compliments
                      </Button>
                    </Paper>)}
                    {canPay && (<Paper>
                      <Button
                        className="btn btn-primary btn-sm white-text align-self-start"
                        variant="contained"
                        onClick={() => proceedPayment()}
                      >
                        Payment
                      </Button>
                    </Paper>)}
                  </Stack>
                </Grid>
                {/*Content */}
                <Grid
                  container
                  xs={12}
                  className="p-lg-2"
                  direction="column"
                  spacing={2}
                >
                  {!Utils.IsNullOrEmptyObject(visit) &&
                    visit.visitType === VisitTypeEnum.Jobcard && (
                      <Grid
                        component={Paper}
                        direction="column"
                        spacing={2}
                        alignItems="baseline"
                        justifyContent="space-evenly"
                        padding="10px"
                        marginBottom="10px"
                        className="font-size-13"
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="baseline"
                          spacing={2}
                        >
                          <Typography>Jobcard Number</Typography>
                          <Typography>{visit.jobcardNumber}</Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="baseline"
                          spacing={2}
                        >
                          <Typography>Make</Typography>
                          <Typography>{visit.vehicleDetails.make}</Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="baseline"
                          spacing={2}
                        >
                          <Typography>Model</Typography>
                          <Typography>{visit.vehicleDetails.model}</Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="baseline"
                          spacing={2}
                        >
                          <Typography>Color</Typography>
                          <Typography>{visit.vehicleDetails.color}</Typography>
                        </Stack>
                      </Grid>
                    )}
                  <TableContainer className="visit-table" component={Paper} style={{ "height": "calc(100vh - 280px)" }}>
                    <Table stickyHeader area-lang="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "20%" }}>Amount</TableCell>
                          <TableCell style={{ width: "20%" }}>Status</TableCell>
                          <TableCell style={{ width: "40%" }}>Link</TableCell>
                          <TableCell>Date & Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paymentInfos.map((row, index) => (
                          <TableRow>
                            <TableCell
                              data-status={row.status}
                              className="visit-card"
                            >
                              <AmountTypography
                                value={
                                  row.captured
                                    ? row.capturedAmount
                                    : row.orderAmount
                                }
                                country={country}
                              />
                            </TableCell>
                            <TableCell>
                              <Typography
                                component="p"
                                className="m-0"
                                fontWeight="800"
                              >
                                {row.status.toUpperCase()}
                              </Typography>
                            </TableCell>
                            {row.paymentType ===
                              PaymentMethodTypes.PaymentLink ? (
                              <TableCell style={{ width: "200px" }}>
                                <Typography
                                  underline="always"
                                  component="p"
                                  variant="body2"
                                  onClick={() => openLink(row.paymentLink)}
                                >
                                  {row.paymentLink}
                                </Typography>
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                            <TableCell>
                              {Utils.ConvertToLocalDT(
                                row.captured
                                  ? row.capturedDT
                                  : row.refunded
                                    ? row.refundDT
                                    : row.createdDT,
                                country
                              )}
                            </TableCell>
                            <TableCell style={{ padding: "0" }}>
                              <div className="menuList">
                                {row.status ===
                                  RazorpayPaymentLinkStatuses.Created && (
                                    <ul>
                                      <li
                                        onClick={(e) => cancelPayment(row, e)}
                                      >
                                        <CancelIcon
                                          fontSize="large"
                                          style={{ color: "#C70000" }}
                                        />
                                        <span>Cancel</span>
                                      </li>
                                      <li
                                        onClick={(e) => updatePayment(row, e)}
                                      >
                                        <CloudSyncIcon
                                          fontSize="large"
                                          style={{ color: "#A020F0" }}
                                        />
                                        <span>Refresh</span>
                                      </li>
                                    </ul>
                                  )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid
                    className="font-size-13"
                    component={Paper}
                    direction="column"
                    marginTop="10px"
                    padding="10px"
                  >
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>Paid</Typography>
                      <AmountTypography value={paid} country={country} />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>Requested</Typography>
                      <AmountTypography value={requested} country={country} />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>Refunded</Typography>
                      <AmountTypography value={refunded} country={country} />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>
                        Discount
                      </Typography>
                      <AmountTypography value={discountAmount} country={country} />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>
                        Outstanding
                      </Typography>
                      <AmountTypography value={outstanding} country={country} />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      <Typography>
                        <strong>Total</strong>
                      </Typography>
                      <AmountTypography value={total} country={country} fontWeight="bold" />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default Payments;
