import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
    SideBarMenuNames,
    SettingsMenuList,
    MaxDateLimit,
} from "../../core/Constants";

import MessagePopup from "../common/MessagePopUp";
import {
    Typography,
} from "@mui/material";
import { Grid, Box, Container, Stack } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { RevenueReportPage, LoginPage } from "../../core/PageConstants";
import CountryHelper from "../../core/CountryHelper";

const RevenueReport = (props) => {
    document.title = "Revenue Report";
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [msgOpen, setMsgOpen] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [selectedDate, setSelectedDate] = useState([
        Utils.GetStartDate(),
        Utils.GetEndDate(),
    ]);
    const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
    const [localSettings, setLocalSettings] = useState(connectToStore());
    const [reportContent, setReportContent] = useState("");

    const checkAuthentication = () => {
        if (Utils.IsNullOrEmptyObject(localSettings)) {
            return;
        }
        UserHelper.CheckPermission(RevenueReportPage, navigate, localSettings);
    };

    useEffect(() => {
        checkAuthentication();
    }, []);

    const handleSubmit = () => {
        getRevenueStatus();
    };

    const getRevenueStatus = async () => {
        setIsLoading(true);
        let reportUrl =
            Utils.GetServiceUrl() +
            "reports/GetEnterpriseReport?" +
            "&businessId=" +
            localSettings.business.businessId +
            "&startDT=" +
            Utils.GetISODateTimeString(selectedDate[0]) +
            "&endDT=" +
            Utils.GetISODateTimeString(selectedDate[1]) +
            "&token=" +
            localSettings.token +
            "&culture=" +
            localSettings.country.Culture;
        setReportContent(reportUrl);

        setTimeout(function () {
            setIsLoading(false);
        }.bind(this), 3000);

    };
    const handleMsgClose = () => {
        setMsgOpen(false);
        if (isNavigateToLogin) {
            UserHelper.LogOut(dispatch);
            navigate(LoginPage.Path);
        }
    };

    const onDateChange = (date) => {
        if (!Utils.IsNullOrEmptyArray(date)) {
            var dateArray = [moment(date[0]), moment(date[1])];
            let dateDiff = dateArray[1].diff(dateArray[0], "days");
            if (dateDiff > MaxDateLimit) {
                setMsgText(
                    "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
                );

                setMsgOpen(true);
            } else {
                setSelectedDate(dateArray);
            }
        }
    };

    return (
        <Container maxWidth="false" className="bg-color p-0">
            <Loader open={isLoading} />
            <MessagePopup
                msgOpen={msgOpen}
                msgText={msgText}
                onMsgClose={handleMsgClose}
            />
            <Box>
                <Grid container m={0}>
                    <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
                    <Grid xs={12} className="content-sec c-sec2">
                        <Grid container direction="row" className="">
                            <SettingsMenuItems
                                selectedMenu={SettingsMenuList.BusinessReports.Revenue}
                            />
                            <Grid flex="1" spacing={2} className="form-sec">
                                <form container spacing={2} onSubmit={handleSubmit}>
                                    <Grid container className="title-sec title-xs">
                                        <Grid xs={12} md={6} lg={6}>
                                            <Typography
                                                level="h1"
                                                component="h2"
                                                fontWeight="600"
                                                fontSize="20px"
                                            >
                                                Revenue Report
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            container
                                            xs={12}
                                            md={6}
                                            lg={5}
                                            style={{ "align-self": "stretch" }}
                                            justifyContent="end"
                                            paddingRight="15px"
                                            className="date-picker-sec"
                                        >
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                style={{ height: "100%" }}
                                            >
                                                <DateRangePicker
                                                    showLeadingZeros={true}
                                                    onChange={onDateChange}
                                                    maxDate={new Date()}
                                                    value={selectedDate}
                                                    locale={CountryHelper.GetDateCulture(
                                                        localSettings.country.Culture
                                                    )}
                                                    clearIcon={null}
                                                    calendarIcon={
                                                        <CalendarMonthOutlinedIcon
                                                            style={{
                                                                "font-size": "24px",
                                                                "align-self": "center",
                                                                color: "#666666",
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </form>
                                <iframe
                                    className="reportIframe card"
                                    src={reportContent}
                                    style={{
                                        width: "100%",
                                        height: "calc(95vh - 205px)"
                                    }}
                                ></iframe>
                                <br />
                                <Stack direction="row" spacing={2} justifyContent="flex-end">
                                    <FormControl>
                                        <Button
                                            className="btn btn-primary"
                                            variant="contained"
                                            type="submit"
                                            onClick={handleSubmit}
                                        >
                                            Show Statement
                                        </Button>
                                    </FormControl>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default RevenueReport;

